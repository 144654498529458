



























import Vue from "vue";
import { email, required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { PasswordRecoveryRequestRequest } from "@/api/security/types/Requests";
import SecurityClient from "@/api/security/SecurityClient";
import AbstractClient from "@/api/AbstractClient";
import { validationMixin } from "vuelidate";

export default Vue.extend({
  name: "PasswordRecoveryForm",
  mixins: [validationMixin],
  data: () => ({
    // loading
    isBusySubmit: false,
    // form data
    form: {
      email: "",
    },
  }),
  validations: {
    form: {
      email: { required, email },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("password_recovery.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("password_recovery.validation.email.email"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: PasswordRecoveryRequestRequest = {
        email: this.form.email,
      };

      this.isBusySubmit = true;
      SecurityClient.passwordRecoveryRequest(request)
        .then(() => {
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.$emit("onError", AbstractClient.getErrorMessage(error));
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
