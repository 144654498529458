














































import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RegistrationActivateForm from "@/components/security/form/RegistrationActivateForm.vue";
import { ActivateAccountRequest } from "@/api/security/types/Requests";
import SecurityClient from "@/api/security/SecurityClient";
import AbstractClient from "@/api/AbstractClient";
import _ from "lodash";

export default Vue.extend({
  name: "RegistrationActivate",
  components: { RegistrationActivateForm, DefaultLayout },
  data: () => ({
    // loading
    isBusySubmit: false,
    // response status
    success: false,
    error: false,
    // error response message
    errorMessage: "",
    // fake timeout
    timeout: 3000,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    hash(): string {
      return this.$route.params.hash.toString();
    },
    showRegistrationActivateForm(): boolean {
      return this.$route.query.password === "true" && !this.isBusy && !this.success && !this.error;
    },
    showProgress(): boolean {
      return this.isBusy;
    },
    showSuccess(): boolean {
      return !this.isBusy && this.success;
    },
    showError(): boolean {
      return !this.isBusy && this.error;
    },
  },
  methods: {
    onSubmit(password?: string): void {
      const request: ActivateAccountRequest = {
        activate_account_hash: this.hash,
      };
      if (_.isString(password)) {
        request.password = password;
      }
      this.isBusySubmit = true;
      setTimeout(() => {
        SecurityClient.activateAccount(request)
          .then(() => {
            this.success = true;
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = AbstractClient.getErrorMessage(error);
          })
          .finally(() => {
            this.isBusySubmit = false;
          });
      }, this.timeout);
    },
  },
  created(): void {
    // if user's new password is not required, send activate request directly
    if (!this.showRegistrationActivateForm) {
      this.onSubmit();
    }
  },
});
