





















































































import Vue, { PropType } from "vue";
import { DeterminedPinCodeResponse } from "@/api/pin-code/types/Responses";
import PinCodeClient from "@/api/pin-code/PinCodeClient";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import DeterminedPinCode from "@/components/pin-code/DeterminedPinCode.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import CreateChildLeftEventForm from "@/components/events/form/CreateChildLeftEventForm.vue";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import _ from "lodash";

export default Vue.extend({
  name: "ScanQrCodeWidget",
  components: { CreateChildLeftEventForm, DeterminedPinCode, QrcodeStream },
  mixins: [validationMixin],
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyDeterminedPinCode: false,
    isBusyReader: false,
    // dialog
    dialog: false,
    // determined PIN code data
    determinedPinCode: null as DeterminedPinCodeResponse | null,
    // form
    form: {
      pin_code: "",
    },
    // reader camera
    camera: "auto",
    // reader states
    responseError: false,
    readerError: false,
  }),
  validations: {
    form: {
      pin_code: { required },
    },
  },
  watch: {
    dialog(dialog: boolean): void {
      if (!dialog) {
        this.responseError = false;
        this.readerError = false;
        this.determinedPinCode = null;
        this.form.pin_code = "";
        this.$nextTick(() => this.$v.form.$reset());
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyDeterminedPinCode || this.isBusyReader;
    },
    pinCodeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.pin_code?.$dirty) return errors;
      !this.$v.form.pin_code.required && errors.push(this.$t("pin_code.validation.pin_code.required"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.dialog = false;
      this.$emit("onSubmit");
    },
    async onReaderInit(promise: Promise<any>): Promise<any> {
      this.isBusyReader = true;
      try {
        await promise;
      } catch (error) {
        this.readerError = true;
      } finally {
        this.isBusyReader = false;
      }
    },
    async onReaderDecode(pinCode: any): Promise<any> {
      if (!_.isString(pinCode)) {
        throw "Decoded content is not a string";
      }

      // fill form field with decoded PIN code
      this.form.pin_code = pinCode;

      // submit
      this.onDeterminePinCode();
    },
    onDeterminePinCode(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.determinePinCode(this.form.pin_code);
    },
    onScanAnotherCode(): void {
      this.camera = "auto";
      this.form.pin_code = "";
      this.determinedPinCode = null;
      this.$nextTick(() => this.$v.form.$reset());
    },
    determinePinCode(pinCode: string): void {
      this.camera = "off";
      this.isBusyDeterminedPinCode = true;
      PinCodeClient.determinePinCode(pinCode, this.schoolClass.id)
        .then((response) => {
          this.camera = "off";
          this.responseError = false;
          this.determinedPinCode = response;
        })
        .catch(() => {
          this.camera = "auto";
          this.responseError = true;
        })
        .finally(() => {
          this.form.pin_code = "";
          this.$nextTick(() => this.$v.form.$reset());
          this.isBusyDeterminedPinCode = false;
        });
    },
  },
});
