
















































































































































import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import Vue, { PropType } from "vue";
import EventClient from "@/api/events/EventClient";
import { CreateChildApologiseEventRequest } from "@/api/events/types/Requests";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { ChildResponse } from "@/api/child/types/Responses";
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "ChildLeavesAloneEventQuickAction",
  mixins: [validationMixin],
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    // dialog
    dialog: false,
    datePicker: false,
    timePicker: false,
    // form data
    form: {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm"),
      message: null,
    },
  }),
  validations: {
    form: {
      date: { required },
      time: { required },
      message: {},
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    dateErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date?.$dirty) return errors;
      !this.$v.form.date.required && errors.push(this.$t("events.quick_actions.child_leaves_alone_event.validation.date.required"));
      return errors;
    },
    timeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.time?.$dirty) return errors;
      !this.$v.form.time.required && errors.push(this.$t("events.quick_actions.child_leaves_alone_event.validation.time.required"));
      return errors;
    },
    messageErrors(): TranslateResult[] {
      return [];
    },
    dateFormatted(): string {
      return moment(this.form.date).format("LL");
    },
    today(): string {
      return moment().format("YYYY-MM-DD");
    },
    now(): string {
      return moment().format("HH:mm:ss");
    },
  },
  methods: {
    onSubmit(): void {
      const request: CreateChildApologiseEventRequest = {
        date: moment(this.form.date).format("YYYY-MM-DD"),
        time: this.form.time + ":00",
        created_for: this.child.id,
        school_class: this.schoolClass.id,
        message: this.form.message,
      };

      this.isBusySubmit = true;
      EventClient.createChildLeavesAloneEvent(request)
        .then((response) => {
          this.dialog = false;
          this.$snackbarSuccess(this.$t("events.quick_actions.child_leaves_alone_event.success"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
