

























import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";

export default Vue.extend({
  name: "SelectedChildWidget",
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
  },
});
