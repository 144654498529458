


















import Vue, { PropType } from "vue";
import { DeterminedPinCodeResponse } from "@/api/pin-code/types/Responses";
import DeterminedPinCodeType from "@/enums/DeterminedPinCodeType";
import DeterminedPinCodeUser from "@/components/pin-code/user/DeterminedPinCodeUser.vue";
import DeterminedPinCodeEvent from "@/components/pin-code/event/DeterminedPinCodeEvent.vue";
import DeterminedPinCodeEventTemplate from "@/components/pin-code/event-template/DeterminedPinCodeEventTemplate.vue";

export default Vue.extend({
  name: "DeterminedPinCode",
  components: { DeterminedPinCodeEventTemplate, DeterminedPinCodeEvent, DeterminedPinCodeUser },
  props: {
    determinedPinCode: {
      type: Object as PropType<DeterminedPinCodeResponse>,
      required: true,
    },
  },
  computed: {
    isUser(): boolean {
      return this.determinedPinCode.type === DeterminedPinCodeType.USER;
    },
    isEvent(): boolean {
      return this.determinedPinCode.type === DeterminedPinCodeType.EVENT;
    },
    isEventTemplate(): boolean {
      return this.determinedPinCode.type === DeterminedPinCodeType.EVENT_TEMPLATE;
    },
  },
});
