
























import Vue, { PropType } from "vue";
import { UserResponse } from "@/api/user/types/Responses";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";

export default Vue.extend({
  name: "DeterminedPinCodeUser",
  components: { PageSubtitle },
  props: {
    user: {
      type: Object as PropType<UserResponse>,
      required: true,
    },
    hideName: {
      type: Boolean,
      default: () => false,
    },
  },
});
