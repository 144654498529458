




















import { ChildrenLimitRestrictionResponse } from "@/api/stripe/types/Responses";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ChildrenLimitRestrictionOverview",
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    restriction: {
      type: Object as PropType<ChildrenLimitRestrictionResponse>,
      required: true,
    },
    currentNumberOfChildren: {
      type: Number,
      required: true,
    },
  },
  computed: {
    color(): string {
      if (this.progress < 75) return "success";
      if (this.progress < 90) return "warning";
      return "error"; // 90 and more
    },
    progress(): number {
      if (this.currentNumberOfChildren === null || this.maxNumberOfChildren === null) return 0;
      return (this.currentNumberOfChildren / this.maxNumberOfChildren) * 100;
    },
    maxNumberOfChildren(): number | null {
      return this.restriction.limit;
    },
  },
});
