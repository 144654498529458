




































import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ConfirmPasswordRecoveryForm from "@/components/security/form/ConfirmPasswordRecoveryForm.vue";

export default Vue.extend({
  name: "ConfirmPasswordRecovery",
  components: { ConfirmPasswordRecoveryForm, DefaultLayout },
  data: () => ({
    // response flags
    success: false,
    error: false,
    // error response message
    errorMessage: "",
  }),
  computed: {
    hash(): string {
      return this.$route.params.hash.toString();
    },
    showConfirmPasswordRecoveryForm(): boolean {
      return !this.success && !this.error;
    },
    showSuccess(): boolean {
      return this.success;
    },
    showError(): boolean {
      return this.error;
    },
  },
  methods: {
    onSuccess(): void {
      this.success = true;
    },
    onError(errorMessage: string): void {
      this.error = true;
      this.errorMessage = errorMessage;
    },
  },
});
