








































import AbstractClient from "@/api/AbstractClient";
import SecurityClient from "@/api/security/SecurityClient";
import { VerifyEmailRequest } from "@/api/security/types/Requests";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Vue from "vue";

export default Vue.extend({
  name: "VerifyEmail",
  components: { DefaultLayout },
  data: () => ({
    // loading
    isBusySubmit: false,
    // response status
    success: false,
    error: false,
    // error response message
    errorMessage: "",
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    hash(): string {
      return this.$route.params.hash.toString();
    },
    showProgress(): boolean {
      return this.isBusy;
    },
    showSuccess(): boolean {
      return !this.isBusy && this.success;
    },
    showError(): boolean {
      return !this.isBusy && this.error;
    },
  },
  methods: {
    onSubmit(): void {
      const request: VerifyEmailRequest = {
        verify_email_confirmation_hash: this.hash,
      };
      this.isBusySubmit = true;
      SecurityClient.verifyEmail(request)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = AbstractClient.getErrorMessage(error);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
  created(): void {
    this.onSubmit();
  },
});
