






























import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import PasswordRecoveryForm from "@/components/security/form/PasswordRecoveryForm.vue";

export default Vue.extend({
  name: "PasswordRecovery",
  components: { PasswordRecoveryForm, DefaultLayout },
  data: () => ({
    // response flags
    success: false,
    error: false,
  }),
  computed: {
    showPasswordRecoveryForm(): boolean {
      return !this.success && !this.error;
    },
  },
  methods: {
    onSuccess(): void {
      this.success = true;
    },
    onError(/* errorMessage: string */): void {
      this.error = true;
    },
  },
});
