


































































import Vue, { PropType } from "vue";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import EventClient from "@/api/events/EventClient";
import { CreateAuthorizedPersonCameEventRequest } from "@/api/events/types/Requests";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { ChildResponse } from "@/api/child/types/Responses";
import moment from "moment";

export default Vue.extend({
  name: "AuthorizedPersonCameEventQuickAction",
  mixins: [validationMixin],
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    // dialog
    dialog: false,
    // form data
    form: {
      message: null,
    },
  }),
  validations: {
    form: {
      message: {},
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    messageErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    onSubmit(): void {
      const request: CreateAuthorizedPersonCameEventRequest = {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:ss"),
        created_for: this.child.id,
        school_class: this.schoolClass.id,
        message: this.form.message,
      };

      this.isBusySubmit = true;
      EventClient.createAuthorizedPersonCameEvent(request)
        .then((response) => {
          this.dialog = false;
          this.$snackbarSuccess(this.$t("events.quick_actions.authorized_person_came_event.success"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
