


















import ChildClient from "@/api/child/ChildClient";
import Vue from "vue";

export default Vue.extend({
  name: "NumberOfChildrenWidget",
  data: () => ({
    // loading
    isBusyChildrenCount: false,
    // number of children
    childrenCount: 0,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyChildrenCount;
    },
  },
  methods: {
    fetchChildrenCount(): void {
      this.isBusyChildrenCount = true;
      ChildClient.getChildrenCount()
        .then((response) => {
          this.childrenCount = response.children_count;
        })
        .finally(() => {
          this.isBusyChildrenCount = false;
        });
    },
  },
  created(): void {
    this.fetchChildrenCount();
  },
});
