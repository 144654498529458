import AbstractClient from "@/api/AbstractClient";
import { DeterminedPinCodeResponse } from "@/api/pin-code/types/Responses";

export default class PinCodeClient extends AbstractClient {
  /**
   * Perform determine PIN code request
   * @param pinCode PIN code
   * @param schoolClassId school class ID
   */
  static determinePinCode(pinCode: string, schoolClassId?: number): Promise<DeterminedPinCodeResponse> {
    let url = "/api/pin-codes/" + encodeURI(pinCode);
    if (schoolClassId) {
      url += "?schoolClassId=" + schoolClassId;
    }
    return AbstractClient.get(url, null, { withoutErrorSnackbar: true })
      .then((response) => new DeterminedPinCodeResponse(response.data));
  }
}
