import AbstractClient from "@/api/AbstractClient";
import { AbstractRestrictionResponse, ChildrenLimitRestrictionResponse } from "@/api/stripe/types/Responses";
import RestrictionType from "@/enums/RestrictionType";
import _ from "lodash";

export default class StripeRestrictionClient extends AbstractClient {
  /**
   * Perform get restrictions by school request
   * @param schoolId school ID
   */
  static getRestrictionsBySchool(schoolId: number): Promise<AbstractRestrictionResponse[]> {
    return AbstractClient.get("/api/stripe/restrictions/by-school/" + schoolId)
      .then((response) => _.toArray(response.data).map((item: any) => StripeRestrictionClient.buildRestriction(item)));
  }

  /**
   * Build restriction of specific type from response data
   * @param data response data
   * @private
   */
  private static buildRestriction(data: any): AbstractRestrictionResponse {
    switch (data.type) {
      case RestrictionType.CHILDREN_LIMIT:
        return new ChildrenLimitRestrictionResponse(data);
      default:
        throw "Unsupported restriction type";
    }
  }
}
