
























import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import LoginForm from "@/components/security/form/LoginForm.vue";

export default Vue.extend({
  name: "Home",
  components: { DefaultLayout, LoginForm },
  created(): void {
    if (this.$isAuthenticated()) {
      this.$router.push({ name: "dashboard" });
    }
  },
});
