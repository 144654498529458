




























import { AllItems } from "@/api/AbstractClient";
import StripeProductClient from "@/api/stripe/StripeProductClient";
import { StripePriceResponse, StripeProductResponse } from "@/api/stripe/types/Responses";
import _ from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "ActiveSubscriptionWidget",
  data: () => ({
    // loading
    isBusyStripeProducts: false,
    // Stripe products
    stripeProducts: [] as StripeProductResponse[],
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyStripeProducts;
    },
    currentStripeProduct(): StripeProductResponse | null {
      return this.stripeProducts.find((product: StripeProductResponse): boolean => product.stripe_prices.find((price: StripePriceResponse): boolean => price.current) !== undefined) ?? null;
    },
    currentStripePrice(): StripePriceResponse | null {
      const product = this.currentStripeProduct;
      return product?.stripe_prices?.find((price: StripePriceResponse): boolean => price.current) ?? null;
    },
    nameFormatted(): string {
      return this.currentStripeProduct?.name ?? _.toString(this.$t("subscriptions.no_plan"));
    },
    amountFormatted(): string {
      const stripePrice = this.currentStripePrice;
      if (stripePrice === null) return "";
      const formatter = new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: stripePrice.currency,
      });
      if (stripePrice.interval !== null) {
        return formatter.format(stripePrice.amount / 100) + " / " + this.$t("subscriptions.recurring_interval." + stripePrice.interval);
      } else {
        return formatter.format(stripePrice.amount / 100);
      }
    },

  },
  methods: {
    fetchStripeProducts(): void {
      this.isBusyStripeProducts = true;
      StripeProductClient.getStripeProducts(new AllItems())
        .then((response) => {
          this.stripeProducts = response.items;
        })
        .finally(() => {
          this.isBusyStripeProducts = false;
        });
    },
  },
  created(): void {
    this.fetchStripeProducts();
  },
});
