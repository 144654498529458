
























































































import Vue from "vue";
import { email, minLength, required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { RegisterRequest } from "@/api/security/types/Requests";
import SecurityClient from "@/api/security/SecurityClient";
import UserClient from "@/api/user/UserClient";
import _ from "lodash";

export default Vue.extend({
  name: "RegistrationForm",
  mixins: [validationMixin],
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyEmail: 0,
    // uniqueness
    emailExists: null,
    // show password in plaintext
    showPassword: false,
    // form data
    form: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      consent: false,
    } as RegisterRequest,
  }),
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: {
        required,
        email,
        unique() {
          return this.isBusyEmail > 0 || this.emailExists === false;
        },
      },
      password: { required, minLength: minLength(8) },
      consent: { checked: (value) => value === true },
    },
  },
  watch: {
    "form.email": _.debounce(function(this: any, email: string): void {
      this.emailExists = null;
      if (!this.$v.form.email.required || !this.$v.form.email.email) {
        return;
      }
      this.isBusyEmail += 1;
      UserClient.getUserByEmailExists(email)
        .then((response) => {
          this.emailExists = response.exists;
        })
        .finally(() => {
          this.isBusyEmail -= 1;
          this.$v.form.email.$touch();
        });
    }, 250),
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyEmail > 0;
    },
    firstNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.first_name?.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push(this.$t("registration.validation.first_name.required"));
      return errors;
    },
    lastNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.last_name?.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push(this.$t("registration.validation.last_name.required"));
      return errors;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("registration.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("registration.validation.email.email"));
      !this.$v.form.email.unique && errors.push(this.$t("registration.validation.email.unique"));
      return errors;
    },
    passwordErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.password?.$dirty) return errors;
      !this.$v.form.password.required && errors.push(this.$t("registration.validation.password.required"));
      !this.$v.form.password.minLength && errors.push(this.$t("registration.validation.password.min_length", { n: 8 }));
      return errors;
    },
    consentErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.consent?.$dirty) return errors;
      !this.$v.form.consent.checked && errors.push(this.$t("registration.validation.consent.checked"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: RegisterRequest = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        password: this.form.password,
      };

      this.isBusySubmit = true;
      SecurityClient.register(request)
        .then(() => {
          this.$snackbarSuccess(this.$t("registration.snackbar.success"));
          this.$router.push({ name: "home" });
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
