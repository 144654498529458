





































































































































































import EventClient from "@/api/events/EventClient";
import { CreateChildLeftEventRequest } from "@/api/events/types/Requests";
import Vue, { PropType } from "vue";
import { AllItems } from "@/api/AbstractClient";
import { ChildAttendanceResponse } from "@/api/child/types/Responses";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import ChildStatus from "@/enums/ChildStatus";
import moment from "moment";
import ChildAttendanceClient from "@/api/child/ChildAttendanceClient";
import _ from "lodash";

const DEPARTURE_TIME_INTERVAL = 15; // 15 minutes

export default Vue.extend({
  name: "ChildrenDepartureTable",
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyChildrenAttendance: false,
    isBusyActions: {} as any,
    // data
    children: [] as ChildAttendanceResponse[],
  }),
  computed: {
    groupedChildrenWithDepartureEvent(): any {
      // filter present children with any departure event
      const children = this.children.filter((child) => child.child_status.id === ChildStatus.CHILD_IN_CLASS)
        .filter((child) => child.departure_event !== null);
      // group children by corresponding time interval
      return _.groupBy(children, function(child: ChildAttendanceResponse): string {
        let includeMinutesToInterval = function(minutes: number | undefined): number {
          if (minutes !== undefined && (60 / DEPARTURE_TIME_INTERVAL) % 1 === 0) {
            for (let i = 60; i >= 0; i -= DEPARTURE_TIME_INTERVAL) {
              if (minutes >= i) {
                return i;
              }
            }
          }
          throw "Should not happen";
        };
        const datetime = child.departure_event?.datetime;
        if (!datetime) {
          throw "Should not happen";
        }
        return datetime.set("minutes", includeMinutesToInterval(datetime.minutes()))
          .toISOString();
      });
    },
    childrenOutOfClass(): ChildAttendanceResponse[] {
      return this.children.filter((child) => child.child_status.id === ChildStatus.CHILD_LEFT);
    },
    showExpansionPanels(): boolean {
      return !_.isEmpty(this.groupedChildrenWithDepartureEvent) ||
        !_.isEmpty(this.childrenOutOfClass);
    },
  },
  methods: {
    onChildLeft(child: ChildAttendanceResponse): void {
      if (!confirm(this.$t("dashboard.child_left_event.confirm").toString())) {
        return;
      }

      const request: CreateChildLeftEventRequest = {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:MM") + ":00",
        created_for: child.child.id,
        school_class: this.schoolClass.id,
        message: null,
      };

      this.$set(this.isBusyActions, child.id, true);
      EventClient.createChildLeftEvent(request)
        .then(() => {
          this.$emit("onSubmit");
        })
        .finally(() => {
          this.$set(this.isBusyActions, child.id, false);
        });
    },
    isBusyAction(child: ChildAttendanceResponse): boolean {
      return child.id in this.isBusyActions && this.isBusyActions[child.id] === true;
    },
    formatFromTime(datetime: string): string {
      return moment(datetime)
        .format("H:mm");
    },
    formatToTime(datetime: string): string {
      return moment(datetime)
        .add(DEPARTURE_TIME_INTERVAL, "minutes")
        .format("H:mm");
    },
    fetchChildrenAttendance(): void {
      this.isBusyChildrenAttendance = true;
      ChildAttendanceClient.getChildrenAttendance(this.schoolClass.id, moment(), new AllItems())
        .then((response) => {
          this.children = response.items;
        })
        .finally(() => {
          this.isBusyChildrenAttendance = false;
        });
    },
  },
  created() {
    this.fetchChildrenAttendance();
  },
});
