
















import { FilterQueryParameters } from "@/api/AbstractClient";
import ChildClient from "@/api/child/ChildClient";
import StripeRestrictionClient from "@/api/stripe/StripeRestrictionClient";
import { AbstractRestrictionResponse } from "@/api/stripe/types/Responses";
import ChildrenLimitRestrictionOverview from "@/components/subscription/misc/ChildrenLimitRestrictionOverview.vue";
import RestrictionType from "@/enums/RestrictionType";
import Vue from "vue";

export default Vue.extend({
  name: "RestrictionsOverview",
  components: { ChildrenLimitRestrictionOverview },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyRestrictions: false,
    isBusyChildren: false,
    // restrictions
    restrictions: [] as Array<AbstractRestrictionResponse>,
    // current number of children in school
    currentNumberOfChildrenInSchool: 0 as number,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyRestrictions || this.isBusyChildren;
    },
  },
  methods: {
    refresh(): void {
      this.fetchRestrictions();
      this.fetchCurrentNumberOfChildrenInSchool();
    },
    isChildrenLimitRestriction(restriction: AbstractRestrictionResponse): boolean {
      return restriction.type === RestrictionType.CHILDREN_LIMIT;
    },
    fetchRestrictions(): void {
      this.isBusyRestrictions = true;
      StripeRestrictionClient.getRestrictionsBySchool(this.schoolId)
        .then((response) => {
          this.restrictions = response;
        })
        .finally(() => {
          this.isBusyRestrictions = false;
        });
    },
    fetchCurrentNumberOfChildrenInSchool(): void {
      const pagination: FilterQueryParameters = {
        page: 1,
        itemsPerPage: 1,
        filter: "",
        sort: "",
      };
      this.isBusyChildren = true;
      ChildClient.getChildrenBySchool(this.schoolId, pagination)
        .then((response) => {
          this.currentNumberOfChildrenInSchool = response._pagination.total;
        })
        .finally(() => {
          this.isBusyChildren = false;
        });
    },
  },
  created() {
    this.fetchRestrictions();
    this.fetchCurrentNumberOfChildrenInSchool();
  },
});
