





















import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RegistrationForm from "@/components/security/form/RegistrationForm.vue";

export default Vue.extend({
  name: "Registration",
  components: { RegistrationForm, DefaultLayout },
});
