











































import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import Vue, { PropType } from "vue";
import EventClient from "@/api/events/EventClient";
import { CreateChildLeftEventRequest } from "@/api/events/types/Requests";
import { ChildResponse } from "@/api/child/types/Responses";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default Vue.extend({
  name: "CreateChildLeftEventForm",
  components: { PageSubtitle, HelpMessage },
  mixins: [validationMixin],
  props: {
    children: {
      type: Array as PropType<ChildResponse[]>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: 0,
    // form data
    form: {
      children: [] as number[],
    },
  }),
  validations: {
    form: {
      children: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit > 0;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.isBusySubmit = this.form.children.length;
      this.form.children.forEach((childId: number) => {
        const request: CreateChildLeftEventRequest = {
          date: moment().format("YYYY-MM-DD"),
          time: moment().format("HH:MM") + ":00",
          created_for: childId,
          school_class: this.schoolClass.id,
          message: null,
        };

        EventClient.createChildLeftEvent(request)
          .then((response) => {
            this.isBusySubmit -= 1;
            if (this.isBusySubmit === 0) {
              this.$snackbarSuccess(this.$t("events.quick_actions.child_left_event.selected_children_left"));
              this.$emit("onSubmit", response);
            }
          });
      });
    },
  },
});
