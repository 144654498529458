import EventClient from "@/api/events/EventClient";
import EventTemplateClient from "@/api/events/EventTemplateClient";
import { UserResponse } from "@/api/user/types/Responses";
import { AbstractEventResponse, AbstractEventTemplateResponse } from "@/api/events/types/Responses";
import DeterminedPinCodeType from "@/enums/DeterminedPinCodeType";
import { ChildResponse } from "@/api/child/types/Responses";
import _ from "lodash";

export class DeterminedPinCodeResponse {
  readonly type: string;
  readonly children: ChildResponse[];
  readonly object: UserResponse | AbstractEventResponse | AbstractEventTemplateResponse;

  constructor(data: any) {
    this.type = data.type;
    this.children = _.toArray(data.children).map((data: any) => new ChildResponse(data));
    switch (data.type) {
      case DeterminedPinCodeType.USER:
        this.object = new UserResponse(data.object);
        break;
      case DeterminedPinCodeType.EVENT:
        this.object = EventClient.buildEvent(data.object);
        break;
      case DeterminedPinCodeType.EVENT_TEMPLATE:
        this.object = EventTemplateClient.buildEventTemplate(data.object);
        break;
      default:
        throw "Unsupported object type";
    }
  }
}
