






































































































































import ChildrenDepartureTable from "@/components/child/table/ChildrenDepartureTable.vue";
import NumberOfChildrenWidget from "@/components/child/widget/NumberOfChildrenWidget.vue";
import ChildLeavesAloneEventQuickAction from "@/components/events/quick-actions/ChildLeavesAloneEventQuickAction.vue";
import ChildLeftEventQuickAction from "@/components/events/quick-actions/ChildLeftEventQuickAction.vue";
import RestrictionsOverview from "@/components/subscription/misc/RestrictionsOverview.vue";
import ActiveSubscriptionWidget from "@/components/subscription/widget/ActiveSubscriptionWidget.vue";
import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassMinimalResponse, SchoolClassResponse } from "@/api/school-class/types/Responses";
import AuthorizedPersonCameEventQuickAction
  from "@/components/events/quick-actions/AuthorizedPersonCameEventQuickAction.vue";
import SmallEventsCalendar from "@/components/events/calendar/SmallEventsCalendar.vue";
import ChildApologiseEventQuickAction from "@/components/events/quick-actions/ChildApologiseEventQuickAction.vue";
import SelectedChildWidget from "@/components/child/widget/SelectedChildWidget.vue";
import BasicInfoWidget from "@/components/child/widget/BasicInfoWidget.vue";
import ShowMyPinCodeQuickAction from "@/components/users/widget/ShowMyPinCodeQuickAction.vue";
import ChildrenAttendanceTable from "@/components/child/table/ChildrenAttendanceTable.vue";
import SchoolClassInfoWidget from "@/components/school-classes/widget/SchoolClassInfoWidget.vue";
import ScanQrCodeWidget from "@/components/qr-code/widget/ScanQrCodeWidget.vue";
import CreateTemporaryLocationWidget from "@/components/temporary-locations/widget/CreateTemporaryLocationWidget.vue";
import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";

export default Vue.extend({
  name: "Dashboard",
  components: {
    ChildrenDepartureTable,
    ChildLeavesAloneEventQuickAction,
    RestrictionsOverview,
    NumberOfChildrenWidget,
    ActiveSubscriptionWidget,
    ChildLeftEventQuickAction,
    CreateTemporaryLocationWidget,
    ScanQrCodeWidget,
    SchoolClassInfoWidget,
    ChildrenAttendanceTable,
    ShowMyPinCodeQuickAction,
    BasicInfoWidget,
    SelectedChildWidget,
    ChildApologiseEventQuickAction,
    SmallEventsCalendar,
    AuthorizedPersonCameEventQuickAction,
    DefaultLayout,
  },
  data: () => ({
    tab: 0 as number,
  }),
  computed: {
    selectedChildAsAuthorizedPerson(): ChildResponse | null {
      return this.$store.getters["Family/getSelectedChild"];
    },
    selectedSchoolClassAsAuthorizedPerson(): SchoolClassResponse | null {
      const child = this.selectedChildAsAuthorizedPerson;
      if (child === null) return null;
      const childToSchoolClass = this.$store.getters["Family/getChildToSchoolClass"](child);
      return childToSchoolClass !== null ? childToSchoolClass.schoolClass : null;
    },
    selectedSchoolAsAdmin(): SchoolResponse | null {
      return this.$store.getters["Setting/getSelectedSchool"];
    },
    selectedSchoolAsTeacher(): SchoolResponse | null {
      return this.$store.getters["Setting/getSelectedSchool"];
    },
    selectedSchoolYearAsTeacher(): SchoolYearResponse | null {
      return this.$store.getters["Setting/getSelectedSchoolYear"];
    },
    selectedSchoolClassAsTeacher(): SchoolClassMinimalResponse | null {
      return this.$store.getters["Setting/getSelectedSchoolClass"];
    },
  },
});
