







import Vue, { PropType } from "vue";
import { AbstractEventResponse } from "@/api/events/types/Responses";
import EventDetail from "@/components/events/calendar/EventDetail.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";

export default Vue.extend({
  name: "DeterminedPinCodeEvent",
  components: { PageSubtitle, EventDetail },
  props: {
    event: {
      type: Object as PropType<AbstractEventResponse>,
      required: true,
    },
  },
});
