



























































































import ChildStatus from "@/enums/ChildStatus";
import Vue, { PropType } from "vue";
import ChildAttendanceClient from "@/api/child/ChildAttendanceClient";
import moment from "moment";
import { AllItems } from "@/api/AbstractClient";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassMinimalResponse, SchoolClassResponse } from "@/api/school-class/types/Responses";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import { TranslateResult } from "vue-i18n";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { CreateTemporaryLocationRequest } from "@/api/temporary-locations/types/Requests";
import TemporaryLocationClient from "@/api/temporary-locations/TemporaryLocationClient";
import { TemporaryLocationResponse } from "@/api/temporary-locations/types/Responses";
import _ from "lodash";

export default Vue.extend({
  name: "CreateTemporaryLocationWidget",
  components: { HelpMessage },
  mixins: [validationMixin],
  props: {
    school: {
      type: Object as PropType<SchoolResponse>,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyAttendance: false,
    isBusySchoolClasses: false,
    isBusySubmit: false,
    // dialogs
    dialog: false,
    // enums
    children: [] as ChildResponse[],
    schoolClasses: [] as SchoolClassResponse[],
    // form data
    form: {
      children: [] as number[],
      target_school_class: null as number | null,
    },
  }),
  validations: {
    form: {
      children: { required },
      target_school_class: { required },
    },
  },
  watch: {
    dialog(dialog: boolean): void {
      if (dialog) {
        this.fetchAttendance();
        this.fetchSchoolClasses();
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyAttendance || this.isBusySchoolClasses || this.isBusySubmit;
    },
    targetSchoolClassErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.target_school_class?.$dirty) return errors;
      !this.$v.form.target_school_class.required && errors.push(this.$t("temporary_locations.quick_actions.create_temporary_location.validation.target_school_class.required"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: CreateTemporaryLocationRequest = {
        date_from: moment().format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        children: this.form.children,
        target_school_class: _.toNumber(this.form.target_school_class),
      };

      this.isBusySubmit = true;
      TemporaryLocationClient.createTemporaryLocation(request)
        .then((response: TemporaryLocationResponse[]) => {
          this.$emit("onSubmit", response);
          this.$snackbarSuccess(this.$t("temporary_locations.quick_actions.create_temporary_location.temporary_location_created"));
          this.dialog = false;
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    fetchAttendance(): void {
      this.isBusyAttendance = true;
      ChildAttendanceClient.getChildrenAttendance(this.schoolClass.id, moment(), new AllItems())
        .then((response) => {
          this.children = response.items
            .filter((attendance) => attendance.child_status.id === ChildStatus.CHILD_IN_CLASS)
            .map((attendance) => attendance.child);
          this.form.children = this.children
            .map((child) => child.id);
        })
        .finally(() => {
          this.isBusyAttendance = false;
        });
    },
    fetchSchoolClasses(): void {
      this.isBusySchoolClasses = true;
      SchoolClassClient.getSchoolClasses(this.school.id, this.schoolYear.id, new AllItems())
        .then((response) => {
          // do not offer current class
          this.schoolClasses = response.items.filter((schoolClass) => schoolClass.id !== this.schoolClass.id);
        })
        .finally(() => {
          this.isBusySchoolClasses = false;
        });
    },
  },
});
