





























import PinCode from "@/components/pin-code/PinCode.vue";
import Vue from "vue";
import UserClient from "@/api/user/UserClient";
import { PinCodeResponse } from "@/api/user/types/Responses";

export default Vue.extend({
  name: "ShowMyPinCodeQuickAction",
  components: { PinCode },
  data: () => ({
    // loading
    isBusyMyPinCode: false,
    // dialog
    dialog: false,
    // PIN code data
    myPinCode: null as PinCodeResponse | null,
  }),
  watch: {
    dialog(value: boolean): void {
      if (value && this.myPinCode === null) {
        this.fetchMyPinCode();
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyMyPinCode;
    },
  },
  methods: {
    fetchMyPinCode(): void {
      this.isBusyMyPinCode = true;
      UserClient.getMyPinCode()
        .then((response) => {
          this.myPinCode = response;
        })
        .finally(() => {
          this.isBusyMyPinCode = false;
        });
    },
  },
});
