







import Vue, { PropType } from "vue";
import { AbstractEventTemplateResponse } from "@/api/events/types/Responses";
import EventTemplateDetail from "@/components/events/calendar/EventTemplateDetail.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";

export default Vue.extend({
  name: "DeterminedPinCodeEventTemplate",
  components: { PageSubtitle, EventTemplateDetail },
  props: {
    eventTemplate: {
      type: Object as PropType<AbstractEventTemplateResponse>,
      required: true,
    },
  },
});
