





























import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "RegistrationActivateForm",
  mixins: [validationMixin],
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    // show password in plaintext
    showPassword: false,
    // form data
    form: {
      password: "",
    },
  }),
  validations: {
    form: {
      password: { required, minLength: minLength(8) },
    },
  },
  computed: {
    passwordErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.password?.$dirty) return errors;
      !this.$v.form.password.required && errors.push(this.$t("registration_activate.validation.password.required"));
      !this.$v.form.password.minLength && errors.push(this.$t("registration_activate.validation.password.min_length", { n: 8 }));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit("onSubmit", this.form.password);
    },
  },
});
