


























































import ChildStatus from "@/enums/ChildStatus";
import Vue, { PropType } from "vue";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import { ChildAttendanceResponse } from "@/api/child/types/Responses";
import ChildAttendanceClient from "@/api/child/ChildAttendanceClient";
import moment from "moment";
import { AllItems } from "@/api/AbstractClient";

export default Vue.extend({
  name: "SchoolClassInfoWidget",
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyAttendance: false,
    // attendance
    attendance: [] as ChildAttendanceResponse[],
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyAttendance;
    },
    childrenWaitingForCount(): number {
      return this.attendance.filter((attendance: ChildAttendanceResponse): boolean => attendance.child_status.id === ChildStatus.CHILD_DOES_NOT_ARRIVE_YET).length;
    },
    childrenInClassCount(): number {
      return this.attendance.filter((attendance: ChildAttendanceResponse): boolean => attendance.child_status.id === ChildStatus.CHILD_IN_CLASS).length;
    },
    childrenLeftClassCount(): number {
      return this.attendance.filter((attendance: ChildAttendanceResponse): boolean => attendance.child_status.id === ChildStatus.CHILD_LEFT).length;
    },
  },
  methods: {
    fetchAttendance(): void {
      this.isBusyAttendance = true;
      ChildAttendanceClient.getChildrenAttendance(this.schoolClass.id, moment(), new AllItems())
        .then((response) => {
          this.attendance = response.items;
        })
        .finally(() => {
          this.isBusyAttendance = false;
        });
    },
  },
  created(): void {
    this.fetchAttendance();
  },
});
