




























































































































import Vue, { PropType } from "vue";
import { AbstractEventTemplateResponse } from "@/api/events/types/Responses";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "EventTemplateDetail",
  props: {
    eventTemplate: {
      type: Object as PropType<AbstractEventTemplateResponse>,
      required: true,
    },
    hideName: {
      type: Boolean,
      default: () => false,
    },
    hideWeekdays: {
      type: Boolean,
      default: () => false,
    },
    hideDate: {
      type: Boolean,
      default: () => false,
    },
    hideTime: {
      type: Boolean,
      default: () => false,
    },
    hideCreatedFor: {
      type: Boolean,
      default: () => false,
    },
    hideCreatedBy: {
      type: Boolean,
      default: () => false,
    },
    hideMessage: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    // weekdays
    selectedWeekdays: [] as number[],
  }),
  computed: {
    weekdays(): TranslateResult[] {
      return ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
        .map((weekday: string): TranslateResult => this.$t("events.weekdays." + weekday));
    },
  },
  created(): void {
    if (this.eventTemplate.repeat_on_monday) this.selectedWeekdays.push(0);
    if (this.eventTemplate.repeat_on_tuesday) this.selectedWeekdays.push(1);
    if (this.eventTemplate.repeat_on_wednesday) this.selectedWeekdays.push(2);
    if (this.eventTemplate.repeat_on_thursday) this.selectedWeekdays.push(3);
    if (this.eventTemplate.repeat_on_friday) this.selectedWeekdays.push(4);
    if (this.eventTemplate.repeat_on_saturday) this.selectedWeekdays.push(5);
    if (this.eventTemplate.repeat_on_sunday) this.selectedWeekdays.push(6);
  },
});
