












































import { AllItems } from "@/api/AbstractClient";
import ChildAttendanceClient from "@/api/child/ChildAttendanceClient";
import { ChildAttendanceResponse, ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import CreateChildLeftEventForm from "@/components/events/form/CreateChildLeftEventForm.vue";
import ChildStatus from "@/enums/ChildStatus";
import moment from "moment";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ChildLeftEventQuickAction",
  components: { CreateChildLeftEventForm },
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyChildren: false,
    // dialog
    dialog: false,
    // school class children
    children: [] as ChildResponse[],
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyChildren;
    },
  },
  watch: {
    dialog(value: boolean): void {
      if (value) {
        this.children = [];
        this.fetchChildren();
      }
    },
  },
  methods: {
    onSubmit(): void {
      this.dialog = false;
      this.$emit("onSubmit");
    },
    fetchChildren(): void {
      this.isBusyChildren = true;
      ChildAttendanceClient.getChildrenAttendance(this.schoolClass.id, moment(), new AllItems())
        .then((response) => {
          this.children = response.items
            .filter((item: ChildAttendanceResponse): boolean => item.child_status.id === ChildStatus.CHILD_IN_CLASS)
            .map((item: ChildAttendanceResponse): ChildResponse => item.child);
        })
        .finally(() => {
          this.isBusyChildren = false;
        });
    },
  },
  created(): void {
    this.fetchChildren();
  },
});
