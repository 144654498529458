





























import Vue from "vue";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { PasswordRecoveryConfirmationRequest } from "@/api/security/types/Requests";
import SecurityClient from "@/api/security/SecurityClient";
import AbstractClient from "@/api/AbstractClient";

export default Vue.extend({
  name: "ConfirmPasswordRecoveryForm",
  mixins: [validationMixin],
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    // show password in plaintext
    showPassword: false,
    // form data
    form: {
      password: "",
    },
    // fake timeout
    timeout: 3000,
  }),
  validations: {
    form: {
      password: { required, minLength: minLength(8) },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    passwordErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.password?.$dirty) return errors;
      !this.$v.form.password.required && errors.push(this.$t("password_recovery.validation.password.required"));
      !this.$v.form.password.minLength && errors.push(this.$t("password_recovery.validation.password.min_length", { n: 8 }));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: PasswordRecoveryConfirmationRequest = {
        password_recovery_confirmation_hash: this.hash,
        password: this.form.password,
      };

      this.isBusySubmit = true;
      setTimeout(() => {
        SecurityClient.passwordRecoveryConfirmation(request)
          .then(() => {
            this.$emit("onSuccess");
          })
          .catch((error) => {
            this.$emit("onError", AbstractClient.getErrorMessage(error));
          })
          .finally(() => {
            this.isBusySubmit = false;
          });
      }, this.timeout);
    },
  },
});
