


































































import PollingService from "@/service/PollingService";
import Vue from "vue";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { LoginRequest } from "@/api/security/types/Requests";
import SecurityClient from "@/api/security/SecurityClient";
import UserClient from "@/api/user/UserClient";
import { ChildToSchoolClassSettingResponse } from "@/api/user/types/Responses";

export default Vue.extend({
  name: "LoginForm",
  mixins: [validationMixin],
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyMyProfile: false,
    // show password in plaintext
    showPassword: false,
    // form data
    form: {
      email: "",
      password: "",
    },
  }),
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyMyProfile;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("login.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("login.validation.email.email"));
      return errors;
    },
    passwordErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.password?.$dirty) return errors;
      !this.$v.form.password.required && errors.push(this.$t("login.validation.password.required"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: LoginRequest = {
        email: this.form.email,
        password: this.form.password,
      };

      this.isBusySubmit = true;
      SecurityClient.login(request)
        .then((loginResponse) => {
          this.isBusyMyProfile = true;
          UserClient.getMyProfile(loginResponse.api_token)
            .then((myProfileResponse) => {
              this.$store.commit("User/setData", {
                id: myProfileResponse.id,
                firstName: myProfileResponse.first_name,
                lastName: myProfileResponse.last_name,
                email: myProfileResponse.email,
                roles: myProfileResponse.roles,
                avatarUrl: myProfileResponse.avatar_url,
              });
              // from login response
              this.$store.commit("ApiToken/setApiToken", loginResponse.api_token);
              this.$store.commit("ApiToken/setExpiresAt", loginResponse.expires_at);
              this.$store.commit("ApiToken/setIsImpersonated", loginResponse.is_impersonated);
              // from my profile response (user data)
              this.$store.commit("Setting/setSelectedSchool", myProfileResponse.setting.selected_school);
              this.$store.commit("Setting/setSelectedSchoolYear", myProfileResponse.setting.selected_school_year);
              this.$store.commit("Setting/setSelectedSchoolClass", myProfileResponse.setting.selected_school_class);
              this.$store.commit("Family/setSelectedChild", { selectedChild: myProfileResponse.setting.selected_child });
              // from my profile response (founder data)
              if (myProfileResponse.founder) {
                this.$store.commit("Stripe/setStripeCustomerId", myProfileResponse.founder.stripe_customer_id);
                this.$store.commit("Stripe/setStripePriceId", myProfileResponse.founder.stripe_price_id);
                this.$store.commit("Stripe/setStripeSubscriptionId", myProfileResponse.founder.stripe_subscription_id);
              }
              myProfileResponse.setting.child_to_school_class_settings.forEach((childToSchoolClass: ChildToSchoolClassSettingResponse): void => {
                this.$store.commit("Family/setChildToSchoolClass", {
                  child: childToSchoolClass.child,
                  schoolClass: childToSchoolClass.school_class,
                });
              });
              // reset polling
              PollingService.getInstance().reset();
              // go to user's dashboard
              this.$router.push({ name: "dashboard" });
            })
            .finally(() => {
              this.isBusyMyProfile = false;
            });
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
